import { Title, TitleProps } from '@mantine/core';
import styles from '~/components/Typograph/Typograph.module.css';

type Props = TitleProps;

function H5(props: Props) {
  return (
    <Title fz={24} fs="normal" fw={300} className={styles.h5} {...props} />
  );
}

export default H5;
